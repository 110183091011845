#nav-dropdown:after { 
  display: none;
}

#home-carousel img{
    width: auto;
    height: 700px;
    max-height: 700px;
    margin: auto;
    display:block
}

.picture {
    padding-right: 10px;
}

.quote {
    color: blue
}

.carousel {
    max-height: '10%';
    overflow: hidden;
  }

.musicImages {
    max-height: 335px;
    object-fit: cover;
    width: '100%';
    overflow: auto;
}

body{
  overflow-x: hidden;
}

#card-container {
  overflow-y: scroll;
  max-height: 83vh;
  padding-right: 5%;
  padding-top: 5%;
}

#background {
  background: url('./HSStool.jpg') no-repeat;
  height: 100vh;

  /* Center and scale the image nicely */
  background-position: top;
  background-repeat: no-repeat;
  background-size: cover;
}